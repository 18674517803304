/* Remove Space Grotesk import */
/* @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap'); */

/* Degular font-face declarations */
@font-face {
  font-family: 'Degular';
  src: url('./fonts/6069bcd4a7e782439333018f_Degular-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Degular';
  src: url('./fonts/6069bcd409bea435ce12c963_Degular-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

/* Improved font-face declarations for Integral CF */
@font-face {
  font-family: 'Integral CF';
  src: url('./fonts/Fontspring-DEMO-integralcf-heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Integral CF';
  src: url('./fonts/Fontspring-DEMO-integralcf-extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

* {
  font-family: 'Degular', sans-serif !important;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Override the global * selector for dramatic-title */
.dramatic-title {
  font-family: 'Integral CF', sans-serif !important;
  margin: 0 !important;
  padding: 0 !important;
  display: block;
  transform-origin: center;
  line-height: 0.7 !important;
}

.letter-animation {
  font-family: 'Integral CF', sans-serif !important;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  opacity: 0;
  transform: translateY(100px);
  animation: appearFromBottom 0.4s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@keyframes appearFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
}

body {
  position: relative;
  perspective: 1500px;
}

::selection {
  background-color: rgba(125, 125, 125, 0.3);
}

.App {
  transition:
    background-color 0.8s ease,
    color 0.8s ease;
  width: 100%;
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

/* Dramatic Full-page Typography */

.name-container {
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Subtle hover effects */
@media (hover: hover) {
  a:hover {
    letter-spacing: 0.03em;
  }
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .name-container {
    padding: 0;
  }

  .letter-animation {
    animation-duration: 0.3s;
  }
}

@media (max-width: 480px) {
  h1 {
    hyphens: auto;
  }
}
